<template>
    <span class="mon-cart-indicator"
          aria-hidden="true"
    >
        <IconCheckmark width="7.45" height="5.25" />
    </span>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.mon-cart-indicator {
    $size: 0.9375rem;

    display: flex;
    align-items: center;
    justify-content: center;

    width: $size;
    height: $size;

    border-radius: 50%;
    background-color: $mon-c-white;
    color: $mon-c-primary-500;

    box-sizing: border-box;
}
</style>
