<script lang="tsx">
import { defineComponentBaseUiBadge } from '@core/app/components/base/ui/BaseUiBadge.vue'

export type Colors = 'primary' | 'basic' | 'success' | 'warning' | 'danger' | 'primary-light'
export type Variants = 'solid'
export  type Sizes = 'sm' | 'md'

export default defineComponentBaseUiBadge<Colors, Variants, Sizes>({
    props: {
        size: {
            default: 'md',
        },
        color: {
            default: 'primary',
        },
        variant: {
            default: 'solid',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiBadge" as *;

@include badge {
    color: $mon-c-white;
}

@include badge--size('sm') {
    @include mon-text-extra-small;

    border-radius: 0.1875rem;
    padding: 0.125rem 0.5rem;
}

@include badge--size('md') {
    @include mon-text-base;

    border-radius: $mon-border-radius;
    padding: 0.25rem 1rem;
}

@include badge--disabled {
    opacity: 0.5;
}

@include badge--color('primary') {
    @include badge--variant('solid', '&') {
        background-color: $mon-c-primary-500;
    }
}

@include badge--color('primary-light') {
    @include badge--variant('solid', '&') {
        background-color: $mon-c-primary-100;
        color: $mon-c-black;
    }
}

@include badge--color('basic') {
    @include badge--variant('solid', '&') {
        background-color: $mon-c-white;
        color: $mon-c-black;
    }
}

@include badge--color('success') {
    @include badge--variant('solid', '&') {
        background-color: $mon-c-success-500;
    }
}

@include badge--color('warning') {
    @include badge--variant('solid', '&') {
        background-color: $mon-c-warning-500;
    }
}

@include badge--color('danger') {
    @include badge--variant('solid', '&') {
        background-color: $mon-c-danger-500;
    }
}

</style>
